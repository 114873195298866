import * as React from 'react';
import classnames from 'classnames';
import { ICoreContext } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

export interface IQuantityBreakoffSelectorProps {
    context: ICoreContext;
    product: SimpleProduct | undefined;
    quantity: number;
    breakoff: boolean;
    onQuantityChange: (quantity: number) => void;
    onBreakoffChange: (breakoff: boolean) => void;
}

const DpakMapping: { [key: string]: string } = {
    'Stålfat': '-',
    'KeyKeg': '-'
};

const QuantityBreakoffSelector = (props: IQuantityBreakoffSelectorProps) => {
    const {
        context,
        product,
        quantity,
        breakoff,
        onQuantityChange,
        onBreakoffChange
    } = props;

    const baseClassName = 'quantity-breakoff-selector';
    const canBreakoff = React.useMemo(() => product?.Behavior?.DefaultQuantity && !product.Behavior.MinimumQuantity, [product]);
    const onlyDpak = React.useMemo(() => !!product?.Behavior?.MinimumQuantity && !!product.Behavior.DefaultQuantity, [product]);

    React.useEffect(() => {
        const defaultQuantity = product?.Behavior?.DefaultQuantity || 1;

        if (canBreakoff) {
            if (quantity > 0 && quantity % defaultQuantity === 0) {
                onBreakoffChange(false);
                return;
            }
        }

        onBreakoffChange(!onlyDpak);
    }, [canBreakoff, onlyDpak]);

    // Check if there is a friendly UOM defined on the SimpleProduct
    const friendlyUOM = product?.ExtensionProperties?.find((x) => x.Key === 'FRIENDLYUOM')?.Value?.StringValue;
    const uom = friendlyUOM || product?.DefaultUnitOfMeasure;

    const onQuantityOptionChange = (newBreakoff: boolean) => {
        if (newBreakoff === breakoff) {
            return;
        }

        // Redo variable here using new option
        const multiplier = product?.Behavior?.DefaultQuantity || 1;
        let newQuantity = quantity;

        if (!newBreakoff) {
            newQuantity = quantity * multiplier;
        } else {
            newQuantity = Math.ceil(quantity / multiplier);
        }

        onQuantityChange(newQuantity);
        onBreakoffChange?.(newBreakoff);
    };

    const boxesLabel = context.request.locale === 'en-US' ? 'BOX(ES)' : 'KASSE(R)';

    return (
        <div className={baseClassName}>
            <div className={`${baseClassName}__options-container`} role='radiogroup' aria-label='TODO'>
                <button
                    className={classnames(`${baseClassName}__option-box`, {
                        'is-selected': product && (onlyDpak || canBreakoff && !breakoff),
                        'not-available': product && !canBreakoff && !onlyDpak
                    })}
                    disabled={!product || !canBreakoff && !onlyDpak}
                    onClick={() => onQuantityOptionChange(false)}
                >
                    {uom && DpakMapping[uom] ? DpakMapping[uom] : boxesLabel}
                </button>
                <button
                    className={classnames(`${baseClassName}__option-box`, {
                        'is-selected': product && (breakoff || !canBreakoff && !onlyDpak),
                        'not-available': product && onlyDpak
                    })}
                    disabled={!product || onlyDpak}
                    onClick={() => onQuantityOptionChange(true)}
                >
                    {uom || '...'}
                </button>
            </div>
        </div>
    );
};

export default QuantityBreakoffSelector;
